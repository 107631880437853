function AjaxAPI() {}

AjaxAPI.prototype = {
  get: function(url, callback) {
    XHR.get({
      url: url,
      success: function(data, status, xhr) {
        if (callback) {
          callback(data);
        }
      }
    });
  },

  delete: function(url, callback) {
    this.putPost('DELETE', null, url, callback)
  },

  put: function(values, submitUrl, callback) {
    this.putPost('PUT', values, submitUrl, callback)
  },

  post: function(values, submitUrl, callback) {
    this.putPost('POST', values, submitUrl, callback)
  },

  putPost: function(verb, values, submitUrl, callback) {
    var token = XHR('meta[name="csrf-token"]').attr('content');

    var postData = {
      'data': values,
      'authenticity_token': token,
      'utf8': '✓'
    };

    var _this = this;

    XHR.ajax({
      type: verb,
      url: submitUrl,
      data: JSON.stringify(postData),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      success: function(data, status, xhr) {
        if (callback) {
          callback(data);
        }
      },
      error: function(data) {
        //console.log(data)
        if (callback) {
          callback(data);
        }
      }
    });
  },

  sendFile: function(values, submitUrl, callback) {
    var token = XHR('meta[name="csrf-token"]').attr('content');

    var postData = new FormData();
    postData.append('authenticity_token', token);
    postData.append('utf8', '✓');
    postData.append('file', values.file, values.file.name);
    postData.append('block_instance_id', values.block_instance_id);
    postData.append('block_type', values.block_type);
    if (values.id) {
      postData.append('id', values.id);
    }

    XHR.ajax({
      type: "POST",
      url: submitUrl,
      data: postData,
      processData: false, // Don't process the files
      contentType: false,
      success: function(data, status, xhr) {
        if (callback) {
          callback(data);
        }
      },
      error: function(data) {
        //console.log(data)
      }
    });
  }
}

export default AjaxAPI;
